export default {
  info: {
    title: 'BBC Reminiscence Archive',
    body: `
    <p>Welcome to the BBC Reminiscence Archive. This archive provides access to a selection of content from the BBC Archives, designed to support reminiscence therapy.</p>
    <p>The principle of reminiscence therapy is to assist people who have dementia to interact and converse in a natural way by stimulating their long-term memory with material from the past.  It is often the case that long-term memory can still function when the person’s working (short-term) memory is degraded.  Tapping into long-term memory can make it possible once again for them to enjoy interacting with others, through their stories.</p>
    <p>A number of reminiscence systems have been developed for people with dementia, based on photographs, photo books, boxes of objects and materials and interactive computer applications.  The BBC wants to contribute to this growing set of supports for people with dementia and their carers by drawing on the enormous fund of photos, videos, and sound clips in its archives.</p>
    <p><strong>To begin, simply select a Theme (such as Sport, Events etc.) or a Decade (1930s, 1940s etc.) and choose whether you wish to have Image, Audio or Video content. The results are randomised each visit, however items can be 'favourited' to return to later.</strong></p>
    <p>For tips on running successful reminiscence sessions, please view <b>Guiding Successful Reminiscence</b> below.</p>
    <p>For more information about dementia, please visit <a target="_blank" href="http://www.alzheimers.org.uk" rel="noopener noreferrer">The Alzheimer’s Society</a>, <a target="_blank" href="http://www.alzscot.org/" rel="noopener noreferrer">Alzheimers Scotland</a> or <a target="_blank" href="http://www.bbc.co.uk/dementia" rel="noopener noreferrer">bbc.co.uk/dementia</a></p>`,
    buttonText: 'Begin',
    deliveryButtonText: 'Guiding Successful Reminiscence',
    deliveryDocument: 'BBC_RemArc_-_Running_successful_reminiscence_sessions.pdf',
  },
  about: {
    title: 'About RemArc',
    body: `
      <p>The BBC Reminiscence Archive (or RemArc) was developed by the BBC, Dundee University and University of St Andrews, and built by Atos as a tool to support people with dementia and carers.</p>
      <p><a target="_blank" href="mailto:archiveaudienceproducts@bbc.co.uk">Contact us about RemArc</a></p>
      <p><a target="_blank" rel="noopener noreferrer" href="https://sound-effects.bbcrewind.co.uk/licensing">Find out about how you can use RemArc content</a></p>
    `,
    buttonText: 'OK',
  },
};
